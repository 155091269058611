

















































import {Component, Prop, Vue} from 'vue-property-decorator';
import G3wButton from '@/components/default/g3wButton.vue';
import {mapGetters} from "vuex";

@Component({
    components: {G3wButton},
    computed: {
        ...mapGetters({
            'sections': 'settings/portalSections'
        })
    }
})

export default class Menu extends Vue {
    @Prop(Boolean) private readonly alwaysExpanded!: boolean;
    @Prop(String) private readonly hoverClasses!: string;
}
