













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {},
    name: 'Search'
})

export default class Search extends Vue {
    @Prop(String) private readonly value!:string;
}
