





















import {Component, Vue} from 'vue-property-decorator';
import MobileCarousel from "@/components/MobileCarousel.vue";

@Component({
    components: {MobileCarousel},
})
export default class Main extends Vue {

}
