













































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from "vuex";

@Component({
    components: {},
    computed: {
            ...mapGetters({
                'sections': 'settings/portalSections',
                'showAdmin': 'settings/showAdminButton'
            })
        }
})

export default class HeaderPA extends Vue {

    get whoIs() {
        return this.$store.getters['me/me'];
    }

    get someoneIsLogged() {
        return this.$store.getters['me/someoneIsLogged'];
    }

    public logout() {
        const locale = this.$i18n.locale;
        this.$store.dispatch('me/logout', {locale});
        this.$store.dispatch('group/reset');
        if (this.$route.name !== 'home') {
            this.$router.push({name: 'home'});
        }
        this.$store.dispatch('menu/setVisibility', {v: false});
    }

    public mounted() {
        // appena apro la barra guardo se c'e' qualcuno loggato
        this.$store.dispatch('me/fetchWhoAmI',{locale:this.$i18n.locale});
    }
}
